
const InternetReview = () => {
    return (
        <>
            <div className="section-inner bg-top-left move-top-bottom tt-overflow-hidden lazyloaded " id="service">
               
                <h1 className="title-block__title pink text-center mt-5">
                INTERNET PLANS, PACKAGES, AND<br/><br/> PRICES REVIEW
                </h1>
                <h3 className="title-block__title text-white text-center mt-3">
                internet plans go as low as $49.99 per month for 300 Mbps.
                </h3>
               
                <div className="container container-fluid-xl mt-5 d-flex justify-content-center">
                    <img src="images/first.jpeg"/>
                </div>
            </div></>
    )
}

export default InternetReview