/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";

const data=[
  {title:"TV Select Signature",
    description:"Your preferred television programs and films are available for viewing on assured tech, satellite TV, and internet streaming options. To help you choose your favorite method of tuning in, we'll break down each of these TV alternatives. See TV Providers"  ,
    price:'$59.99/mo',
    op1:"150+ channels",
    op2:"Includes local and basic assured tech channels",
    op3:"Channels include NBC, FX, and Magnolia Network",
  

   
  },
  {title:"TV Select Signature + Entertainment View",
  description:"You should have affordable internet that offers a ton of data at fast download speeds. We examine and investigate all of the main US internet providers so that your home network will function flawlessly.See Internet Providers"  ,
  price:'$71.99/mo',
  op1:"205+ channels",
  op2:"Adds 80+ basic assured tech channels",
  op3:"Channels include Disney XD, Cooking Channel, and OWN",


  
},
{title:"TV Select Signature + Sports View + Entertainment View",
description:"You should be able to get internet at a great price that offers gobs of data and high download speeds. We examine and investigate all of the main US internet providers so that your home network will function flawlessly.Compare Providers",
price:'$77.99/mo',
op1:"228+ channels",
op2:"Adds premium live sports networks",
op3:"Channels include NFL RedZone, ESPNews, Outdoor Channel",


 
},

]
const ServiceCard = () => {
  

  return (
    <>
      <div className="tt-position-relative tt-overflow-hidden pt-5 mt-2" id="prices">
        <div className="lazyload tt-obj03-bg" data-bg="images/wrapper06.png" />
        <div className="section-indent mt-0">
          <div className="container container-fluid-lg">
          
            <div className="promo02__wrapper row js-init-carusel-tablet slick-default">
              {data.map((e)=>{ 
                return(
                  <>
                <div className="tt-item col-md-4 my-3">
                  <div className="promo02 js-handler px-0 rounded">
                   
                    <div className="mt-4 text-center" style={{paddingLeft:"20px",paddingRight:"20px"}}><h3>{e.title}</h3></div>
                    <div className="w-100 p-3">
                    <h2
                className=""
                style={{ borderLeft: "2px solid #35c5fc", paddingLeft: "20px" }}
              >
               {e.price}
              </h2>
              <ul className="font-weight-bold mt-5 ml-4 gap-2" style={{listStyle:"disc"}}>
                <li>
                {e.op1}
                </li>
                <li>
                {e.op2}
                </li> <li>
                {e.op3}
                </li>
              </ul>
              <button type="button" class="btn planbt mt-4">View Plans</button>
                      {/* <div className="d-flex flex-column">
                        <div className="d-flex justify-content-around">
                          <p className="my-2">4 T-Shirts</p>
                          <p className="my-2">AED 6</p>
                        </div>
                        <div className="d-flex justify-content-around">
                          <p className="my-2">4 T-Shirts</p>
                          <p className="my-2">AED 6</p>
                        </div>
                        <div className="d-flex justify-content-around">
                          <p className="my-2">4 T-Shirts</p>
                          <p className="my-2">AED 6</p>
                        </div>
                        <div className="d-flex justify-content-around">
                          <p className="my-2">4 T-Shirts</p>
                          <p className="my-2">AED 6</p>
                        </div>
                      </div> */}
                    </div>
                    {/* <div className="tt-btn tt-btn__wide">
                      <button type="submit" className="button">
                        Price $rwet
                      </button>
                    </div> */}
                  </div>
                </div>
                </>)})
}


            </div>
            <div className="container-fluid mt-4">
               <div className="row gap-2 d-flex align-items-center justify-content-center">
                  <div className="col-10"><input className="w-100 mt-3 zip p-2 rounded" style={{border:"none"}} placeholder="Zip Code" /></div>
                  <div className="col-2"><button className="planbt2">FIND</button></div>

               </div>
            </div>
          </div>
        </div>
      </div>

      
    </>
  );
};

export default ServiceCard;
