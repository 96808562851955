import React from "react";

const About = () => {
  return (
    <>
      <div className="section-inner bg-top-left move-top-bottom tt-overflow-hidden lazyloaded " id="service">
        <div className="ml-5">
          <h1 className="title-block__title pink ml-5">
            SERVICES
          </h1>
        </div>
        <h1 className="title-block__title pink text-center mt-5">
          TV PACKAGES
        </h1>
        <div className="container container-fluid-xl mt-3">
          <div className="box01 mt-5">

            <div
              className="box01__img rounded"
              style={{ backgroundImage: 'url("/images/ser1.jpg")' }}
            >

            </div>
            <div className="box01__content">
              <div style={{ borderLeft: "2px solid #35c5fc", paddingLeft: "20px", height: '40px' }}>
              </div>
              <div className="title-block mt-2">
                <h4 className="title-block__title text-white">
                  TV packages starting at
                </h4>
              </div>
              {/* <div className="box01__wrapper-img">
                <img
                  src="/images/img01.jpg"
                  className="lazyload"
                  data-src="/images/img01.jpg"
                  alt=""
                />
              </div> */}
              <h2
                className="text-white"
                style={{ borderLeft: "2px solid #35c5fc", paddingLeft: "20px" }}
              >
                $59.99/mo.
              </h2>
              <ul className="font-weight-bold mt-5 gap-2" style={{ listStyle: "disc",color:"white" }}>
                <li>
                  Up to 228+ channels with add-on packs
                </li>
                <li>
                  Comes with free Peacock Premium
                </li> <li>
                  Watch on the go with ’s TV app
                </li> <li>
                  No assured tech box requirements
                </li>
              </ul>
              <button type="button" class="btn planbt mt-4">View Plans</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
