/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

// const data=[
//   {title:"How To Watch NFL Games 2023",
//     description:"Are you prepared for a football game? Discover the top methods for watching and streaming NFL games in 2023–2024, along with important dates and channel listings."  ,
//     image:"/images/user1.jpg"
//   },
//   {title:"How To Watch Upcoming Awards Shows 2023",
//   description:"See your preferred television program, movie, or artist with our schedule of forthcoming award shows. Please don't worry if anything is marked as TBD; we will provide an update as soon as we learn more about the event."  ,
//   image:"/images/user2.jpg"
// },
// {title:"How to Watch Live TV News for Free: ABC, CBS, FOX News, NBC, and More",
// description:"News travels even quicker than life itself. Fortunately, there are several live TV news networks that you can watch and stream for free during these exciting moments.",
// image:"/images/user3.jpg"  
// },
// {title:"How To Watch the NBA In-Season Tournament 2023",
// description:"Fans may expect a new gaming edge as the NBA gets ready for its 78th season. The NBA In-Season Tournament, which begins in November, is expected to provide another level of interest to the first half of the regular season.",
// image:"/images/user4.jpg"  
// },
// {title:"How To Watch Upcoming Sports Events 2023",
// description:"With our often updated TV sports calendar, you can experience the thrills and chills of every big sporting event in 2023",
// image:"/images/user5.jpg"  
// },
// {title:"TV Premieres November 2023",
// description:"With more than 100 new and returning TV series and films, such as Only Murders In the Building, Billions, and Physical, we're enjoying the dog days of summer.",
// image:"/images/user6.jpg"  
// },
// ]
const Services = () => {
  return (
    <>
      <div className="section-indent mt-5" >
        <div className="container container-fluid-lg">
          <div className="title-block text-center">
            <h4 className="title-block__title mt-4 pink">LET'S START WATCHING!</h4>
            <div className="title-block__label text-white">
            Superfans, celebrate! Whether it's professional football, live news, or streaming original content, <br/>our TV recommendations make it easy for you to enjoy the entertainment you love.
            </div>
          </div>
          <div className="row2 img-box__wrapper mt-4">
          <div className="col-custom-450 col-6 col-md-4">
              <div className="img-box">
                <div className="img-box__img">
                  <a href="#">
                    <img
                      src="/images/user1.jpg"
                      className=" lazyloaded"
                      data-src="/images/img08.jpg"
                      alt=""
                    />
                  </a>
                </div>
                <a href="#" className="img-box__title text-white">
                How To Watch NFL Games 2023
                </a>
                <p className="text-white">
                Are you prepared for a football game? Discover the top methods for watching and streaming NFL games in 2023–2024, along with important dates and channel listings.
                </p>
               
              </div>
            </div>
            <div className="col-custom-450 col-6 col-md-4 custommg">
              <div className="img-box">
                <div className="img-box__img">
                  <a href="#">
                    <img
                      src="/images/user2.jpg"
                      className=" lazyloaded"
                      data-src="/images/img08.jpg"
                      alt=""
                    />
                  </a>
                </div>
                <a href="#" className="img-box__title text-white">
                How To Watch Upcoming Awards Shows 2023
                </a>
                <p className="text-white">
                See your preferred television program, movie, or artist with our schedule of forthcoming award shows. Please don't worry if anything is marked as TBD; we will provide an update as soon as we learn more about the event.
                </p>
               
              </div>
            </div>
            </div>


            <div className="row2 img-box__wrapper mt-4">
          <div className="col-custom-450 col-6 col-md-4">
              <div className="img-box">
                <div className="img-box__img">
                  <a href="#">
                    <img
                      src="/images/user3.jpg"
                      className=" lazyloaded"
                      data-src="/images/img08.jpg"
                      alt=""
                    />
                  </a>
                </div>
                <a href="#" className="img-box__title text-white">
                How to Watch Live TV News for Free: ABC, CBS, FOX News, NBC, and More
                </a>
                <p className="text-white">
                "News travels even quicker than life itself. Fortunately, there are several live TV news networks that you can watch and stream for free during these exciting moments.
                </p>
               
              </div>
            </div>
            <div className="col-custom-450 col-6 col-md-4 custommg">
              <div className="img-box">
                <div className="img-box__img">
                  <a href="#">
                    <img
                      src="/images/user4.jpg"
                      className=" lazyloaded"
                      data-src="/images/img08.jpg"
                      alt=""
                    />
                  </a>
                </div>
                <a href="#" className="img-box__title text-white">
                How To Watch the NBA In-Season Tournament 2023
                </a>
                <p className="text-white">
                Fans may expect a new gaming edge as the NBA gets ready for its 78th season. The NBA In-Season Tournament, which begins in November, is expected to provide another level of interest to the first half of the regular season.
                </p>
               
              </div>
            </div>
            </div>


            <div className="row2 img-box__wrapper mt-4">
          <div className="col-custom-450 col-6 col-md-4">
              <div className="img-box">
                <div className="img-box__img">
                  <a href="#">
                    <img
                      src="/images/user5.jpg"
                      className=" lazyloaded"
                      data-src="/images/img08.jpg"
                      alt=""
                    />
                  </a>
                </div>
                <a href="#" className="img-box__title text-white">
                How To Watch Upcoming Sports Events 2023
                </a>
                <p className="text-white">
                With our often updated TV sports calendar, you can experience the thrills and chills of every big sporting event in 2023
                </p>
               
              </div>
            </div>
            <div className="col-custom-450 col-6 col-md-4 custommg">
              <div className="img-box">
                <div className="img-box__img">
                  <a href="#">
                    <img
                      src="/images/user6.jpg"
                      className=" lazyloaded"
                      data-src="/images/img08.jpg"
                      alt=""
                    />
                  </a>
                </div>
                <a href="#" className="img-box__title text-white">
                TV Premieres November 2023
                </a>
                <p className="text-white">
                With more than 100 new and returning TV series and films, such as Only Murders In the Building, Billions, and Physical, we're enjoying the dog days of summer.
                </p>
               
              </div>
            </div>
            </div>
   
        </div>
      </div>
    </>
  );
};

export default Services;
