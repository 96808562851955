/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

const Footer = () => {
  return (
    <>
    <div class="footer">
            <div class="container">     
                <div class="row">                       
                    <div class="col-lg-4 col-sm-4 col-xs-12">
                        <div class="single_footer">
                            <h4 className="pink">TV & Internet Providers</h4>
                            <ul>
                                <li><a href="#service">Shop TV</a></li>
                                <li><a href="#service">Internet Review</a></li>
                                <li><a href="#service">Tv review</a></li>
                                <li><a href="#service">Cheapest Internet</a></li>

                              
                                
                            </ul>
                        </div>
                    </div>
                    <div class="col-md-4 col-sm-4 col-xs-12">
                        <div class="single_footer single_footer_address">
                            <h4 className="pink">Local</h4>
                            <ul>
                                <li><a href="#contact">Contact</a></li>
                                <li><a href="#">Home</a></li>
                               
                            </ul>
                        </div>
                    </div>
                    <div class="col-md-4 col-sm-4 col-xs-12">
                        <div class="single_footer single_footer_address">
                            <h4 className="pink">Find Providers in Your Area</h4>
                            <div class="signup_form">                           
                                <form action="#" class="subscribe">
                                    <input type="text" class="subscribe__input" placeholder="Zip Code"/>
                                    <button type="button" class="planbt btnpink">FIND</button>
                                </form>
                            </div>
                        </div>
                        {/* <div class="social_profile">
                            <ul>
                                <li><a href="#"><i class="fab fa-facebook-f"></i></a></li>
                                <li><a href="#"><i class="fab fa-twitter"></i></a></li>
                                <li><a href="#"><i class="fab fa-google-plus-g"></i></a></li>
                                <li><a href="#"><i class="fab fa-instagram"></i></a></li>
                            </ul>
                        </div>                           */}
                    </div>        
                </div>
                <div class="row">
                    <div class="">
                        <p class="copyright">© 2010-2023 Web Link. All rights reserved.</p>
                    </div>                
                </div>               
            </div>
        </div>
    </>
  );
};

export default Footer;
