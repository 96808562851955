/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

const data=[
  {title:"TV SERVICE",
    description:"Your preferred television programs and films are available for viewing on assured tech, satellite TV, and internet streaming options. To help you choose your favorite method of tuning in, we'll break down each of these TV alternatives. See TV Providers"  ,
    image:"/images/im1.png"
  },
  {title:"INTERNET SERVICE",
  description:"You should have affordable internet that offers a ton of data at fast download speeds. We examine and investigate all of the main US internet providers so that your home network will function flawlessly.See Internet Providers"  ,
  image:"/images/im2.png"
},
{title:"COMPARE PROVIDERS",
description:"You should be able to get internet at a great price that offers gobs of data and high download speeds. We examine and investigate all of the main US internet providers so that your home network will function flawlessly.Compare Providers",
image:"/images/im3.png"  
},
{title:"HELPFUL RESOURCES",
description:"You should have access to affordable internet that offers large data volumes and quick download speeds. To ensure that your home Wi-Fi is fantastic, we investigate and evaluate each of the main US internet providers. Browse Articles",
image:"/images/im4.png"  
},
]
const Packages = () => {
  

  return (
    <>
      <div className="tt-position-relative tt-overflow-hidden pt-5 mt-5" id="prices">
        <div className="lazyload tt-obj03-bg" data-bg="images/wrapper06.png" />
        <div className="section-indent mt-0">
          <div className="container container-fluid-lg">
            <div className="title-block text-center">
              <h6 className="title-block__title pink mt-5">LET US PROVIDE YOU WITH THE GREATEST INTERNET AND TV SERVICE.</h6>
              <div className="title-block__label text-white mt-3 text-center">
              Here at assured tech.com, we make it simple to find and internet in your region<br/> so you can go straight to the enjoyable part: owning TV and internet.
              </div>
            </div>
            <div className="promo02__wrapper row js-init-carusel-tablet slick-default">
              {data.map((e)=>{ 
                return(
                  <>
                <div className="tt-item col-md-4 my-3">
                  <div className="promo02 js-handler px-0 rounded">
                    <div className="w-100 d-flex justify-content-center">
                      <img
                        src={e.image}
                        style={{width:"130px"
                          
                         
                        }}
                        alt=""
                      />
                    </div>
                    <div className="mt-3"><h3>{e.title}</h3></div>
                    <div className="w-100 p-3">
                      <p>{e.description}</p>
                      {/* <div className="d-flex flex-column">
                        <div className="d-flex justify-content-around">
                          <p className="my-2">4 T-Shirts</p>
                          <p className="my-2">AED 6</p>
                        </div>
                        <div className="d-flex justify-content-around">
                          <p className="my-2">4 T-Shirts</p>
                          <p className="my-2">AED 6</p>
                        </div>
                        <div className="d-flex justify-content-around">
                          <p className="my-2">4 T-Shirts</p>
                          <p className="my-2">AED 6</p>
                        </div>
                        <div className="d-flex justify-content-around">
                          <p className="my-2">4 T-Shirts</p>
                          <p className="my-2">AED 6</p>
                        </div>
                      </div> */}
                    </div>
                    {/* <div className="tt-btn tt-btn__wide">
                      <button type="submit" className="button">
                        Price $rwet
                      </button>
                    </div> */}
                  </div>
                </div>
                </>)})
}


            </div>
          </div>
        </div>
      </div>

      
    </>
  );
};

export default Packages;
