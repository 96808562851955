/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-has-content */
import React, { useState } from "react";

const Header = () => {
  const [open] = useState(false);
  return (
    <>
      <>
        <nav
          className={`panel-menu ${open ? "mmitemopen" : null}`}
          id="mobile-menu"
        >
          <div className="mmpanels">
            <div className="mmpanel mmopened mmcurrent" id="mm0">
              <ul>
                <li className="active pt-5">
                  <a href="#">Home</a>
                </li>
                <li className="subMenu">
                  <a href="#prices" data-target="#mm1" className="mm-next-level">
                    Prices
                  </a>
                </li>
                <li className="subMenu">
                  <a href="#mm4" data-target="#mm4" className="mm-next-level">
                    Laundry Patners
                  </a>
                </li>
                <li className="subMenu">
                  <a href="#about" data-target="#mm5" className="mm-next-level">
                    About
                  </a>
                </li>
                <li>
                  <a href="#">News Rooms</a>
                </li>
                <li>
                  <a href="#service">Services</a>
                </li>
                <li>
                  <a href="#contact">Contacts</a>
                </li>
                <li id="entrypoint-objects">
                  <div className="tt-mibilemenu-layout">
                    <div className="tt-mibilemenu-icon">
                      <div className="tt-item">
                        <a href="#" className="tt-obj__btn p-0">
                          <i
                            class="fa-brands fa-facebook text-white"
                            style={{ fontSize: "30px" }}
                          ></i>
                          <i
                            class="fa-brands fa-instagram text-white mx-2"
                            style={{ fontSize: "30px" }}
                          ></i>
                        </a>
                      </div>
                    </div>
                    <a
                      href="#"
                      className="tt-btn not-style"
                      data-toggle="modal"
                      data-target="#modalMRequestQuote"
                    >
                      <span className="mask">Login</span>
                      <div className="button">Login</div>
                    </a>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </nav>

        <header id="tt-header">
          <div id="js-init-sticky">
            <div className="tt-holder-wrapper">
              <div className="tt-holder">
                <div className="tt-col-left">
                  {/* logo */}
                  <a href="#" className="tt-logo tt-logo-alignment">
                    <img src={'/images/logos.png'} width={"30%"} height={"25%"} alt="" />
                  </a>
                  {/* /logo */}
                </div>
                <div className="tt-col-center tt-col-wide tt-col-objects text-center justify-content-end">
                  <div className="tt-col__item">
                    {/* desktop-nav */}
                    <nav id="tt-nav">
                      <ul style={{gap:"50px"}}> 
                        <li>
                          <a href="#">Home</a>
                        </li>
                        {/* <li>
                          <a href="#prices">Price</a>
                        </li>
                        <li>
                          <a href="#">Laundry Patners</a>
                        </li>
                        <li>
                          <a href="#about">About</a>
                        </li>
                        <li>
                          <a href="#">News Rooms</a>
                        </li> */}
                        <li>
                          <a href="#service">Services</a>
                        </li>
                        <li>
                          <a href="#contact">Contacts</a>
                        </li>
                      </ul>
                    </nav>
                    {/* /desktop-nav */}
                  </div>
                </div>
                {/* <div className="tt-col-right tt-col-objects">
                  <div className="tt-col__item d-block d-lg-none">
                    <div className="h-info02">
                      <div className="tt-item">
                        <address>
                          <a href="tel:1(800)7654321">
                            <span className="icons-483947" /> 1 (800) 765-43-21
                          </a>
                        </address>
                      </div>
                    </div>
                  </div>
                  <div className="tt-col__item">
                    <div className="tt-obj tt-obj-cart js-dropdown-cart">
                      <a href="#" className="tt-obj__btn">
                        <i class="fa-brands fa-facebook text-white"></i>
                      </a>
                    </div>
                  </div>
                  <div className="tt-col__item">
                    <div className="tt-obj tt-obj-cart js-dropdown-cart">
                      <a href="#" className="tt-obj__btn">
                        <i class="fa-brands fa-instagram text-white"></i>
                      </a>
                    </div>
                  </div>
                  <div className="tt-col__item d-none d-md-block">
                    <a
                      href="#"
                      className="tt-btn"
                      data-toggle="modal"
                      data-target="#modalMRequestQuote"
                    >
                      <span className="mask">Login</span>
                      <div className="button">Login</div>
                    </a>
                  </div>
                  <div className="tt-col__item toggle-wrapper">
                    {open ? (
                      <i
                        class="fa-solid fa-xmark"
                        onClick={() => setOpen(!open)}
                      ></i>
                    ) : (
                      <i
                        class="fa-solid fa-bars"
                        onClick={() => setOpen(!open)}
                      ></i>
                    )}
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </header>
      </>
    </>
  );
};

export default Header;
