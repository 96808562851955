import React from 'react'
import Layout from '../components/Layout/Layout'
import HomeSection01 from '../components/HomeSection01'

const HomePage = () => {
  return (
    <>
      <Layout>
        <HomeSection01 />
      </Layout>
    </>
  )
}

export default HomePage