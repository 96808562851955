/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/iframe-has-title */
import React from "react";
import Packages from "./Packages";
import Services from "./Services";
import About from "./About";
import Contact from "./Contact";
import ServicesCard from "./ServiceCard";
import InternetReview from "./InternetReview";
import TvReview from "./TvReview";
import CheapestInternet from "./CheapestInternet";

const HomeSection01 = () => {
  return (
    <>
      <main id="tt-pageContent">
        <div className="container-fluid">
          <div
            className="blurred-background"
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "75%",
              backgroundImage: 'url("/images/background.jpg")',
              backgroundPosition: "center",
              backgroundSize: "cover",
              zIndex: -1,
            }}
          ></div>
          <div className="row ml-5 mt-5">
            <div className="col-lg-12 ml-5  d-flex flex-column justify-content-start align-items-start">
              <div className="w-100 d-flex justify-content-start pt-5">
                <img
                  src="/images/company.svg"
                  alt=""
                  style={{ width: "400px" }}
                />
              </div>
              <h1 className="home-h1">FIND INTERNET & ASSURED<br/> TECH PROVIDERS IN YOUR AREA
</h1>
              <h3 className="pb-4">Best Internet and Tv providers in your area.</h3>
              {/* <div className="d-flex flex-wrap justify-content-center">
                <img
                  src="/images/google.webp"
                  style={{ width: "180px", height: "60px" }}
                  alt=""
                />
                <img
                  src="/images/apple.webp"
                  style={{ width: "180px", height: "60px" }}
                  alt=""
                />
              </div> */}
            </div>
          </div>
        </div>

        <Packages />
        {/* <HowDoes />
        <AppFeature />  */}
        <Services />
        <About />
        <ServicesCard/>
        <InternetReview/>
        <TvReview/>
        <CheapestInternet/>
        {/* <WhoWeAre />
        <CustomerFeed /> */}
        <Contact />
      </main>
    </>
  );
};

export default HomeSection01;
