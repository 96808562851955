
const TvReview = () => {
    return (
        <>
            <div className="section-inner bg-top-left move-top-bottom tt-overflow-hidden lazyloaded  " id="service">
               
                <h1 className="title-block__title pink text-center mt-5">
                TV PACKAGES, PRICES, AND<br/> <br/>PLANS REVIEW
               
                </h1>
                <h3 className="title-block__title text-white text-center mt-3">
                offers one base TV package: 150+ channels for $59.99/mo. Read on to learn about bundles and add-ons.
                </h3>
               
                <div className="container container-fluid-xl mt-5 d-flex justify-content-center">
                    <img src="images/second.jpeg"/>
                </div>
            </div></>
    )
}

export default TvReview