/* eslint-disable jsx-a11y/iframe-has-title */
import React from "react";

const Contact = () => {
  return (
    <>
      <div className="tt-posirion-relative pt-5" id="contact">
        <div className="container container-fluid-lg">
          <div className="title-block text-center">
            <h4 className="title-block__title  pink">CONTACT US</h4>
            <div className="title-block__label text-white">
              As you might expect of a company that began as a high-end interiors contractor, we pay strict attention.
            </div>

          </div>
          <div className="title-block__label text-white" style={{ marginLeft: "94px" }}>
            Email
          </div>
          <div className="title-block__label text-white" style={{ marginLeft: "94px" }}>
            Click more detail & information   <a href="mailto:info@assured-tech.com" className="text-primary">info@assured-tech.com</a>
          </div>
          <div className="title-block__label text-white" style={{ marginLeft: "94px" }}>

          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="map-layout">
                <form
                  className="form-default"
                  id="contact-form"
                  onSubmit={(event) => event.preventDefault()}
                >
                  <div className="row row-align-col">
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          type="text"
                          name="email"
                          className="form-control"
                          placeholder="Name"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          type="email"
                          name="phonenumber"
                          className="form-control"
                          placeholder="Email"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <textarea
                      name="message"
                      rows={7}
                      className="form-control"
                      placeholder="Your message"
                      defaultValue={""}
                    />
                  </div>
                  <div className="">
                    <button type="submit" className="button btn planbt" >
                      Send Message
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
